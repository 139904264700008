import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import SpeakerGroup from "@material-ui/icons/SpeakerGroupRounded";
import GraphicEq from "@material-ui/icons/GraphicEqRounded";
import Shopping from "@material-ui/icons/AddShoppingCartRounded";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function OurServices() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Our Services</h2>
          <h5 className={classes.description}>
            As a sound engineering company we offer a varity of services relating to the setup and delivery of quality sound and lighting.
            We support all kinds of events from garden parties and pub bands up to multi-week conserts.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fully Serviced"
              description="Whether you're looking for a garden party or major consert, let us know and we'll do all the hard work.  We'll setup your venue to maximise the sound quality and bring it to life with lights.
              We have everything you'll need from projectors to follow spots; radio mics to multi-racks.  Let us make your event a truely memorable one!"
              icon={SpeakerGroup}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="The Light Touch"
              description="Running a small bash and in need of a little umph?  We'll pull together a package to suit your needs."
              icon={GraphicEq}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="For Hire"
              description="Need some additional capacity?  Take a look through our catelogue to see what tickles your fancy."
              icon={Shopping}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
