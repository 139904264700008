import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/nbsLandingPage.js";

// Sections for this page
import OurServices from "./Sections/OurServices.js";
import WhoAreWe from "./Sections/WhoAreWe.js";
import ContactUs from "./Sections/ContactUs.js";
import Gallery from "./Sections/Gallery.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function NBSLandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="NBSoundReinforcement"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/desk-2.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div><h1 className={classes.title}>NBS</h1></div>
              <div><h2 className={classes.title}>Sound Reinforcement</h2></div>
              <h4>
                Whether you're running an event and in need of some bad ass sounds or looking to hire some equipment to keep your show on the road, get in touch and see how we can help!
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <OurServices />
          <WhoAreWe />
          <Gallery />
          <ContactUs />
        </div>
      </div>
      <Footer />
    </div>
  );
}
