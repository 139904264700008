import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import nick from "assets/img/faces/nick-1.jpg";
import dan from "assets/img/faces/dan-1.jpg";

const useStyles = makeStyles(styles);

export default function WhoAreWe() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Who Are We?</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={nick} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Nick Bell
                <br />
                <small className={classes.smallTitle}>Sound Engineer</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  After following his passion from a young age, Nick founded the NBS:SoundReinforcement company to further his ability in offering sound engineering.
                  With a over 10 years in the industry, Nick knows what he's talking about when it comes to dropping the beat.
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={dan} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Dan Jenkins
                <br />
                <small className={classes.smallTitle}>Lighting Engineer</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  With a wealth of experiance managing the lighting rigs on some of the worlds biggest cruse liners, you know you're in for a treat when Dan's about.  Just sit back and enjoy the show!
                </p>
              </CardBody>
            </Card>
          </GridItem>

          
        </GridContainer>
      </div>
    </div>
  );
}
